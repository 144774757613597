<template>
  <spinner v-if="!post" large center />
  <column-layout v-else class="page post-page" sticky-sides>
    <!-- Left -->
    <template> </template>

    <!-- Main -->
    <template v-slot>
      <div class="card widget pa">
        <Post :post="post" />
      </div>
    </template>

    <!-- Right -->
    <template> </template>
  </column-layout>
</template>

<script>
import Post from "@/components/app/posts/Post.vue";

export default {
  components: { Post },

  metaInfo: {
    title: "Post",
  },

  data: () => ({
    post: null,
  }),

  created() {
    this.fetchPost();
  },

  methods: {
    fetchPost() {
      this.$store
        .dispatch("posts/fetchSingle", this.$route.params.id)
        .then((post) => {
          this.post = post;
        })
        .catch((err) => {
          this.$utils.handleError(err);
        });
    },
  },
};
</script>

<style lang="scss">
.post-page {
  .left-column {
    @media screen and (max-width: 959px) {
      display: none;
    }
  }
}
</style>
